var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-table"},[_c('CDataTable',{attrs:{"items":_vm.ticket_data,"fields":_vm.fields,"loading":_vm.loading},on:{"row-clicked":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"contact",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('h6',[_vm._v(" "+_vm._s(item.contact_email)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.contact_number)+" ")])])]}},{key:"issue",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('div',[_c('h6',[_vm._v(" "+_vm._s(item.issue)+" ")])]),_c('div',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:({ movable: false }),expression:"{ movable: false }"}],class:("ticket-images-" + (item._id))},_vm._l((item.files),function(file,index){return _c('img',{key:index,staticClass:"ticket-image-st",attrs:{"src":file}})}),0),(item.files && item.files.length)?_c('button',{staticClass:"ticket-img-view-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.showTicketImage(item._id)}}},[_vm._v(" View ")]):_vm._e()])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.moment(item.createdAt).format("LLL"))+" ")])]}},{key:"updatedAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.moment(item.updatedAt).format("LLL"))+" ")])]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPage,"pages":_vm.totalPages},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }