<template>
  <div class="ticket-table">
    <CDataTable
      :items="ticket_data"
      :fields="fields"
      :loading="loading"
      @row-clicked="rowClickHandler"
    >
      <template #contact="{ item }">
        <td>
          <h6>
            {{ item.contact_email }}
          </h6>
          <div>
            {{ item.contact_number }}
          </div>
        </td>
      </template>
      <template #issue="{ item }">
        <td>
          <div>
            <h6>
              {{ item.issue }}
            </h6>
          </div>
          <div>
            {{ item.description }}
          </div>

          <div
            :class="`ticket-images-${item._id}`"
            v-viewer="{ movable: false }"
          >
            <img
              class="ticket-image-st"
              v-for="(file, index) in item.files"
              :key="index"
              :src="file"
            />
          </div>
          <button
            v-if="item.files && item.files.length"
            class="ticket-img-view-btn"
            type="button"
            @click="showTicketImage(item._id)"
          >
            View
          </button>
        </td>
      </template>
      <template #createdAt="{ item }">
        <td>
          {{ moment(item.createdAt).format("LLL") }}
        </td>
      </template>
      <template #updatedAt="{ item }">
        <td>
          {{ moment(item.updatedAt).format("LLL") }}
        </td>
      </template>
    </CDataTable>
    <CPagination :active-page.sync="currentPage" :pages="totalPages" />
  </div>
</template>

<script>

import TicketServiceApi from "@/services/api/tickets";
import "viewerjs/dist/viewer.css";
import { directive as viewer } from "v-viewer";
export default {
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },

  data() {
    return {
      ticket_data: [],
      fields: [
        "module",
        "issue",
        "contact",
        "status",
        "platform",
        "os_version",
        "createdAt",
        "updatedAt",
      ],

      currentPage: 1,
      totalPages: 10,
      loading: true,
    };
  },
  watch: {
    currentPage(current, prev) {
      this.loadAllTickets(current);
    },
  },
  mounted() {
    this.loadAllTickets();
  },
  methods: {
    loadAllTickets(page = 1) {
      this.loading = true;
      TicketServiceApi.getAllTickets({
        page: page,
        limit: 10,
      })
        .then((res) => {
          this.ticket_data = res.data.docs;
          this.currentPage = res.data.page;
          this.totalPages = res.data.totalPages;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showTicketImage(id) {
      const viewer = this.$el.querySelector(`.ticket-images-${id}`).$viewer;
      viewer.show();
    },
    rowClickHandler(item, index, column, event) {
      if (event.target.className !== "ticket-img-view-btn") {
        const id = item._id;
        this.$router.push({ name: "TicketDetails", params: { id } });
      }
    },
  },
};
</script>

<style>
.ticket-image-st {
  height: 42px;
  width: 42px;
  border-radius: 8px;
  margin: 4px;
}
.ticket-img-view-btn {
  background-color: #121d43;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}
.ticket-table {
  background-color: #fff;
  padding: 20px;
  margin: 0 10px 10px 10px 10px;
  border-radius: 20px;
}
</style>



